import React, { useRef, useEffect, useContext } from "react";
import { CursorContext } from "./layout";
import { useViewResize } from "../hooks/useViewResize";
import { useMousePosition } from "../hooks/useMousePosition";

import "../styles/05-app/cursorBlob.scss"
import DenmanIcon from "../images/svg/d-icon.svg";
//Animation libraries
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger.js";
gsap.registerPlugin(ScrollTrigger);


export default function BlobCanvas(props) {
  let blobRadius = [50, 40, 10, 20, 30];
  const { viewWidth, viewHeight } = useViewResize();
  const { clientX, clientY } = useMousePosition();
  const cursor = useContext(CursorContext);

  //const BLOB_MAX_ZINDEX = 888;
  const CANVAS_WIDTH = viewWidth;
  const CANVAS_HEIGHT = viewHeight;
  const canvasBlobRef = useRef();

  //let blobMaxZIndex = props.loadedIn === "home" ? 887 : 888;

  //console.log("blobMaxZIndex: " + blobMaxZIndex);

  class Blob {
    constructor() {
      this.points = [];
      this.oldMousePoint = { x: 0, y: 0 };
      this.hover = false;
    }

    init() {
      for (let i = 0; i < this.numPoints; i++) {
        let point = new Point(
          this.divisional * (i + 1) + Math.random() * 0.3,
          this
        );
        // point.acceleration = -1 + Math.random() * 2;
        // console.log(point);
        this.push(point);
      }
    }

    push(item) {
      if (item instanceof Point) {
        this.points.push(item);
      }
    }

    render() {
      let canvas = this.canvas;
      let ctx = this.ctx;
      // let position = this.position;
      let pointsArray = this.points;
      // let radius = this.radius;
      let points = this.numPoints;
      // let divisional = this.divisional;
      // let center = this.center;
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      //first point
      pointsArray[0].solveWith(pointsArray[points - 1], pointsArray[1]);

      let p0 = pointsArray[points - 1].position;
      let p1 = pointsArray[0].position;
      let _p2 = p1;

      ctx.beginPath();
      ctx.moveTo(this.oldMousePoint.x, this.oldMousePoint);
      ctx.moveTo((p0.x + p1.x) / 2, (p0.y + p1.y) / 2);
      for (let i = 1; i < points; i++) {
        pointsArray[i].solveWith(
          pointsArray[i - 1],
          pointsArray[i + 1] || pointsArray[0]
        );
        //console.log('left point: ' + pointsArray[i - 1].position + "right point: " + pointsArray[i + 1].position, ", " + pointsArray[0].position)
        let p2 = pointsArray[i].position;
        var xc2 = (p1.x + p2.x) / 2;
        var yc2 = (p1.y + p2.y) / 2;
        ctx.quadraticCurveTo(p1.x, p1.y, xc2, yc2);
        //ctx.lineTo(p2.x, p2.y);

        //ctx.fillStyle = '#000000';
        // ctx.fillRect(p1.x-2.5, p1.y-2.5, 5, 5);

        p1 = p2;
      }

      var xc = (p1.x + _p2.x) / 2;
      var yc = (p1.y + _p2.y) / 2;
      ctx.quadraticCurveTo(p1.x, p1.y, xc, yc);
      // ctx.lineTo(_p2.x, _p2.y);

      if (this.gradient) {
        var gradient = ctx.createLinearGradient(45, 45, 45, 600);

        gradient.addColorStop(0, "#000000");
        gradient.addColorStop(1, "#979797");
        ctx.fillStyle = gradient;
        ctx.fill();
      } else if (this.lineWidth === 0) {
        ctx.fillStyle = this.color;
        ctx.fill();
      } else {
        ctx.lineWidth = this.lineWidth;
        ctx.strokeStyle = this.color;
        ctx.lineCap = "round";
        ctx.stroke();
      }

      //  //little box showing mouse pos
      //       ctx.fillStyle = '#000000';
      //       if(this.mousePos) {
      //         let angle = Math.atan2(this.mousePos.y, this.mousePos.x) + Math.PI;
      //         ctx.fillRect(center.x + Math.cos(angle) * this.radius, center.y + Math.sin(angle) * this.radius, 5, 5);
      //       }
      requestAnimationFrame(this.render.bind(this));
    }

    move(e, canvasEl) {
      let blob = this;
      let pos = blob.center;
      var rect = canvasEl.getBoundingClientRect();
      var relativePosX = e.clientX - rect.left; //x position within the element.
      var relativePosY = e.clientY - rect.top; //y position within the element.
      let diff = { x: relativePosX - pos.x, y: relativePosY - pos.y };
      let dist = Math.sqrt(diff.x * diff.x + diff.y * diff.y);
      let angle = null;

      // console.log(this, e.clientY, e.clientX);
      // console.log(this.hover);
      // console.log("dist: " + dist + ", radius: " + blob.radius);

      if (dist < blob.radius && this.hover === false) {
        let vector = { x: relativePosX - pos.x, y: relativePosY - pos.y };
        //console.log("angle1: " + angle);
        angle = Math.atan2(vector.y, vector.x);
        this.hover = true;
        // blob.color = '#77FF00';
      } else if (dist > blob.radius && this.hover === true) {
        let vector = { x: relativePosX - pos.x, y: relativePosY - pos.y };
        angle = Math.atan2(vector.y, vector.x);
        //console.log("angle2: " + angle);
        this.hover = false;
        // blob.color = null;
      }

      if (typeof angle == "number" && this.hover) {
        let nearestPoint = null;
        let distanceFromPoint = 200;

        blob.points.forEach((point) => {
          if (Math.abs(angle - point.azimuth) < distanceFromPoint) {
            // console.log(point.azimuth, angle, distanceFromPoint);
            nearestPoint = point;
            distanceFromPoint = Math.abs(angle - point.azimuth);
          }
        });

        if (nearestPoint) {
          let strength = {
            x: this.oldMousePoint.x - relativePosX,
            y: this.oldMousePoint.y - relativePosY,
          };
          strength =
            Math.sqrt(strength.x * strength.x + strength.y * strength.y) * 10;
          if (strength > 100) strength = 100;
          //if (strength < 10) strength = 10;
          nearestPoint.acceleration = (strength / 100) * (this.hover ? -1 : 1);
          //console.log("strength: " + strength);
        }
      }

      this.oldMousePoint.x = relativePosX;
      this.oldMousePoint.y = relativePosY;
    }

    bubble() {
      for (let i = 0; i < this.numPoints; i++) {
        let point = this.points[i];
        //point.acceleration = -1 + Math.random() * 2;
        point.acceleration = -0.3 + Math.random() * 0.6;
      }
    }

    set color(value) {
      this._color = value;
    }
    get color() {
      //return this._color || '#000000';
      return this._color || "rgba(255,255,255,0.3)";
    }

    set gradient(bool) {
      this._gradient = bool;
    }
    get gradient() {
      return this._gradient || false;
    }

    set lineWidth(value) {
      this._lineWidth = value;
    }
    get lineWidth() {
      return this._lineWidth || 0;
    }

    set canvas(value) {
      if (
        value instanceof HTMLElement &&
        value.tagName.toLowerCase() === "canvas"
      ) {
        this._canvas = value;
        this.ctx = this._canvas.getContext("2d");
      }
    }
    get canvas() {
      return this._canvas;
    }

    set numPoints(value) {
      if (value > 2) {
        this._points = value;
      }
    }
    get numPoints() {
      // return this._points || 32;
      return this._points || 9;
    }

    set radius(value) {
      if (value > 0) {
        this._radius = value;
      }
    }
    get radius() {
      // return this._radius || 50;
      // console.log(cursor.active);
      return this._radius || cursor.size;
    }

    set position(value) {
      if (typeof value == "object" && value.x && value.y) {
        this._position = value;
      }
    }
    get position() {
      return this._position || { x: 0.5, y: 0.5 };
    }

    get divisional() {
      // return Math.PI * 2 / this.numPoints;
      return (Math.PI * 1.85) / this.numPoints;
    }

    get center() {
      return {
        x: this.oldMousePoint.x,
        y: this.oldMousePoint.y,
        //  x: this.canvas.width * this.position.x,
        //  y: this.canvas.height * this.position.y,
      };
    }

    set running(value) {
      this._running = value === true;
    }
    get running() {
      return this.running !== false;
    }
  }

  class Point {
    constructor(azimuth, parent) {
      this.parent = parent;
      this.azimuth = Math.PI - azimuth;
      this._components = {
        x: Math.cos(this.azimuth),
        y: Math.sin(this.azimuth),
      };

      this.acceleration = -0.3 + Math.random() * 0.6;
    }

    solveWith(leftPoint, rightPoint) {
      this.acceleration =
        (-0.3 * this.radialEffect +
          (leftPoint.radialEffect - this.radialEffect) +
          (rightPoint.radialEffect - this.radialEffect)) *
        this.elasticity -
        this.speed * this.friction;
      //console.log("acceleration: " + this.acceleration);
      //console.log("speed: " + this.speed);

      //console.log("leftPoint.radialEffect: " + leftPoint.radialEffect);
    }

    set acceleration(value) {
      if (typeof value == "number") {
        this._acceleration = value;
        this.speed += this._acceleration * 2;
      }
    }
    get acceleration() {
      return this._acceleration || 0;
    }

    set speed(value) {
      if (typeof value == "number") {
        this._speed = value;
        this.radialEffect += this._speed * 2; //5;
      }
    }
    get speed() {
      return this._speed || 0;
    }

    set radialEffect(value) {
      if (typeof value == "number") {
        this._radialEffect = value;
      }
    }
    get radialEffect() {
      return this._radialEffect || 0;
    }

    get position() {
      return {
        x:
          this.parent.center.x +
          this.components.x * (this.parent.radius + this.radialEffect),
        y:
          this.parent.center.y +
          this.components.y * (this.parent.radius + this.radialEffect),
      };
    }

    get components() {
      return this._components;
    }

    set elasticity(value) {
      if (typeof value === "number") {
        this._elasticity = value;
      }
    }
    get elasticity() {
      return this._elasticity || 0.002;
      // return this._elasticity || 0.0015;
    }
    set friction(value) {
      if (typeof value === "number") {
        this._friction = value;
      }
    }
    get friction() {
      // return this._friction || 0.0085;
      return this._friction || 0.0085;
    }
  }


  useEffect(() => {
    const BLOB = new Blob();
    // console.log(CANVAS_WIDTH, CANVAS_HEIGHT)
    BLOB.canvas = canvasBlobRef.current;
    BLOB.color = "black;"
    BLOB.init();
    BLOB.numPoints = 9;
    BLOB.radius = blobRadius;
    BLOB.render();

    //console.log(cursor);


    let cursorMove = function (event) {
      // console.log(BLOB);
      BLOB.move(event, canvasBlobRef.current);
    };

    // ScrollTrigger.create({
    //   trigger: "#vantaBG",
    //   start: "top top", 
    //   end: "top top", // 200px past the start 
    //   pin: "#cursorBlob"
    // });
    window.addEventListener("pointermove", cursorMove);
    window.addEventListener("touchend", cursorMove);
    return () => {
      window.removeEventListener("pointermove", cursorMove);
      window.removeEventListener("touchend", cursorMove);
    }
  }, []);

  return (
    <div id="cursorBlob" className="blob-canvas"
      style={{
        position: "absolute",
        width: `${CANVAS_WIDTH}px`,
        height: `${CANVAS_HEIGHT}px`,
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
      }}>
      <DenmanIcon id={`${props.loadedIn}DenmanIcon`} alt="Denman Icon" width="160px" height="160px" style={{ opacity: 0, position: "absolute", display: "none", zIndex: "1000" }} />
      <canvas
        ref={canvasBlobRef}
        touch-action="none"
        is-active="false"
        className={`blob ${cursor.active}`}
        width={CANVAS_WIDTH}
        height={CANVAS_HEIGHT}
        style={{
          position: "fixed",
          // zIndex: 888,
          // opacity: 1,
          //zIndex:`${cursor.active === props.loadedIn || cursor.firstLoad ? (cursor.transitioning || cursor.hover || cursor.firstLoad ? blobMaxZIndex : 0):0}`,
          zIndex: props.loadedIn === "header" ? 888 : 1,
          opacity: `${cursor.active === props.loadedIn || cursor.firstLoad ? (cursor.transitioning || cursor.hover || cursor.firstLoad ? 1 : 0) : 0}`,
          left: 0,
          top: 0
          // transition: `transform 0.4s ease-in-out`,
          // transform:`translate(-50%,-50%) scale(${cursor.active})`
        }}
      ></canvas>
    </div>
  );
}
