import { useEffect, useState } from "react";

export const useViewResize = () => {

  const [size, setSize] = useState({
    viewWidth: 0,
    viewHeight: 0,
    clientWidth: 0,
    clientHeight: 0,
  });

  const updateSize = event => {
      const viewWidth = window.innerWidth;
      const viewHeight = window.innerHeight;
      const clientWidth = document.body.clientWidth;
      const clientHeight = document.body.clientHeight;
    setSize({
      viewWidth,
      viewHeight,
      clientWidth,
      clientHeight,
    });
  };

  useEffect(() => {
  
    updateSize()
    window.addEventListener('resize', updateSize, false);
    
    return () => {
      window.removeEventListener('load', updateSize);
      window.removeEventListener('resize', updateSize, false);
    };
  }, []);

  return size;
};
