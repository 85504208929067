import { navigate } from "gatsby";
import { useContext, useCallback } from "react";
import { CursorContext } from "../components/layout";

//Animations libraries
import gsap from "gsap";
import { Power1 } from "gsap";
import { DrawSVGPlugin } from "gsap/DrawSVGPlugin";
//import { NoToneMapping } from "three";
gsap.registerPlugin(DrawSVGPlugin);

export const useCursorHandlers = (options = {}) => {
    const cursor = useContext(CursorContext);
    const blobGrowTime = cursor.transitionTime / 5; //Used on Grow and Shrink
    const iconAnimationTime = cursor.transitionTime / 5 * 3;


    const clearTimeouts = () => {
        for (var i = 0; i < cursor.timeouts.length; i++) {
            clearTimeout(cursor.timeouts[i]);
        }
        cursor.timeouts = [];
    };
    const toggleCursor = (active, size) => {
        cursor.size = size;
        cursor.active = active;
        // console.log("currentSize: " + size);
    };
    const transitionCursor = (tlsize) => {
        cursor.size = tlsize;
    };

    const onClick = useCallback(event => {
        //console.log("OPTIONS");
        //console.log(options.callback);

        if (options.callback) {
            options.callback.handleMenuClick();
        }

        //console.log("cursor.transitioning: " + cursor.transitioning);

        if (!cursor.transitioning) {
            cursor.transitioning = true;
            if (cursor.active === "home") {
                //animate out Story, Design, Code text on homepage
                gsap.to(".description-links .blob-link", iconAnimationTime / 6000, { y: -50, opacity: 0, ease: Power1.easeOut, stagger: 0.2 })
                gsap.to(".description-container", iconAnimationTime / 6000, { opacity: 0, ease: Power1.easeOut })
            }
            clearTimeouts();
            for (let i = 0; i <= 1000; i++) {
                cursor.timeouts.push(setTimeout(function () {
                    transitionCursor(options.tlsize / 1000 * i);
                    if (i === 1000) {
                        //Blob is full grown
                        navigate(options.link);
                        document.body.scrollTop = document.documentElement.scrollTop = 0;
                        cursor.transitionTime = 2500;
                        cursor.currentPage = options.link.split('/')[1];
                        gsap.killTweensOf("*"); //clean gsap listeners
                        // Icon animation
                        const iconAnimationTimeSec = iconAnimationTime / 1000;
                        //check if !cursor.isTouch before animating icon
                        if (!cursor.isTouch) {
                            gsap.set(`#${cursor.active}DenmanIcon .st2`, { drawSVG: 0 });
                            gsap.set(`#${cursor.active}DenmanIcon`, { y: -100, opacity: 0, display: "block" });
                            gsap.to(`#${cursor.active}DenmanIcon`, iconAnimationTimeSec / 6, { y: 0, opacity: 1, ease: Power1.easeOut });
                            gsap.to(`#${cursor.active}DenmanIcon .st2`, { drawSVG: true, duration: iconAnimationTimeSec / 3, ease: Power1.easeOut, delay: (iconAnimationTimeSec / 6 + 0.1) });
                            gsap.to(`#${cursor.active}DenmanIcon .st2`, { fill: "white", duration: iconAnimationTimeSec / 6, ease: Power1.easeOut, delay: iconAnimationTimeSec / 2 });
                            gsap.to(`#${cursor.active}DenmanIcon`, iconAnimationTimeSec / 6, { y: 0, opacity: 0, ease: Power1.easeOut, delay: iconAnimationTimeSec / 6 * 5 })
                            gsap.set(`#${cursor.active}DenmanIcon .st2`, { drawSVG: 0, fill: "none", delay: 3 });
                        }
                        // Blob animation shrink
                        /*
                        cursor.timeouts.push(setTimeout(function() {
                            for (let i=1000; i>=0; i--) { 
                                cursor.timeouts.push(setTimeout(function() { 
                                    if(i===0){
                                        cursor.transitioning=false; 
                                        cursor.hover=false; //turn off transitioning state
                                        toggleCursor("",options.tlsize/1000*i);
                                    } else {
                                        toggleCursor(options.active,options.tlsize/1000*i);
                                    }
                                }, blobGrowTime/1000 * -(i-1000))); 
                            }
                        },
                            iconAnimationTime
                        ))*/
                    }
                }, blobGrowTime / 1000 * i));
            }
        }
    });

    const onMouseEnter = useCallback(event => {
        if (options.onMouseEnter) {
            options.onMouseEnter(event);
        }
        let currentSize = 0;
        if (!cursor.transitioning) {
            if (cursor.hover) {
                currentSize = cursor.timeouts.length; // check current size of the blob
            } else {
                cursor.hover = true;
            }
            clearTimeouts();
            for (let i = currentSize; i <= 1000; i++) {
                cursor.timeouts.push(setTimeout(function () {
                    toggleCursor(options.active, options.size / 1000 * i);
                    cursor.timeouts.shift(); // clear used timeout
                }, 400 / 1000 * (i - currentSize)));
            }
        }
    });

    const onMouseLeave = useCallback(event => {
        if (options.onMouseLeave) {
            options.onMouseLeave(event);
        }
        if (!cursor.transitioning) {
            clearTimeouts();
            for (let i = 1000; i >= 0; i--) {
                cursor.timeouts.push(setTimeout(function () {
                    if (i === 0) {
                        // Turn off Transitioning States
                        cursor.transitioning = false;
                        cursor.hover = false;
                        toggleCursor("", options.size / 1000 * i);
                        cursor.timeouts.shift(); // clear used timeout
                    } else {
                        toggleCursor(options.active, options.size / 1000 * i);
                        cursor.timeouts.shift(); // clear used timeout
                    }
                }, 400 / 1000 * -(i - 1000)));
            }
        }
    });

    return { onMouseEnter, onMouseLeave, onClick };
};

