/**
 * Animation component Help Guide
 *
 * Wrapper component that animates using gsap and scrolltrigger
 *
 * *** Parameters ***
 * >> wrapperElements
 * - Element that will wrap animated component .
 * - Default element is a div to wrap the component to be animated
 *
 * >> direction
 * - Set the direction that the element will animate from.
 * - Default value: null - will fade on spot;
 * - values: "up","down", "left", "right"
 *
 * >> duration
 * - Set animation duration
 * - Default value: 0.3
 *
 * >> delay
 * - Set animation delay
 * - Default value: 0
 *
 * >> stagger
 * - set stagger animation to the elements children
 * - Default value: false, stagger delay 0.2
 * - e.g.: stagger = [true, 0.5]
 *   stagger = [*set stagger to true*, *stagger*]
 *
 *
 *
 */

import React, { useRef, useEffect, useState } from "react";
import { gsap, Power1 } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger.js";

//import { useDidMountEffect } from "../../hooks/useDidMountEffect";


//GSAP plugins
gsap.registerPlugin(ScrollTrigger)


const ComponentAnimateInAnimation = ({
  children,
  wrapperElement = "div",
  stagger = [false, 0.2],
  direction = null,
  duration = 0.3,
  delay = 0,
  easing = Power1.easeOut,
  wrapperClass = "",
  animationStart = "top 80%",
  isMarkdownWrapper = false,

  ...props
}) => {
    const Component = wrapperElement;
    const [isLoaded, setIsLoaded] = useState(false);

    let compRef = useRef(null);
    const distance = 100;
    //const startPosClass = direction ? "dd-animate--start-"+direction : "";
    let animateDirection;
    let display;

    // let staggerElements = gsap.utils.selector(compRef);
    if (Component === "span") {
        display = {display: `inline-block`};
    }

    // Component === "span"
    // ? (display = {display: `inline-block`})
    // : (display = {display: `inherit`});

    switch (direction) {
        case "left":
            animateDirection = {x: `${-distance}px`, y: 0};
            break;
        case "right":
            animateDirection = {x: `${distance}px`, y: 0};
            break;
        case "up":
            animateDirection = {x: 0, y: `${distance}px`};
            break;
        case "down":
            animateDirection = {x: 0, y: `${-distance}px`};
            break;
        default:
            animateDirection = {x: 0, y: 0};
    }

    useEffect(() => {
        // console.log("ComponentAnimateInAnimation > useLayoutEffect");
        let target = stagger[0] ? compRef.current.childNodes : compRef.current;
        if (isMarkdownWrapper) {
            target = compRef.current.children[0].children;
        }
        // if(isMarkdownWrapper){console.log("MARKDOWN TARGET");console.log(target)};
        //gsap.set(target, { overwrite: true, autoAlpha: 0, ...animateDirection});

        //setIsLoaded(true);
        gsap.killTweensOf(target);
        gsap.set(target, {clearProps: true});
        gsap.set(target, {overwrite: true, autoAlpha: 0, ...animateDirection});

        if (wrapperElement === "blockquote") {
            let quote = compRef.current;
            gsap.to(quote, duration, {
                autoAlpha: 1,
                x: 0,
                y: 0,
                overwrite: true,
                ease: easing,
                delay,
                scrollTrigger: {
                    trigger: target,
                    start: animationStart,
                    invalidateOnRefresh: true,
                    //  onEnter: ()=>{console.log("ANIMATE IN: Blockquote")}
                    //  markers: true
                },
            });
            // return () => {
            //   gsap.killTweensOf(quote);
            //   gsap.set(quote, {clearProps: true});
            // }
        }

        if (stagger[0]) {
            //there is a stagger

            gsap.to(target, duration, {
                autoAlpha: 1,
                x: 0,
                y: 0,
                overwrite: true,
                //duration: duration,
                // className: "+=dd-animate-in",
                ease: easing,
                stagger: stagger[1],
                scrollTrigger: {
                    trigger: compRef.current,
                    start: animationStart,
                    invalidateOnRefresh: true,
                    //onEnter: ()=>{console.log("ANIMATE IN: ")}
                },
            });

            // return () => {
            //   gsap.killTweensOf(target);
            //   gsap.set(target, {clearProps: true});
            //   console.log("ComponentAnimateInAnimation > useLayoutEffect stagger return > kill target");
            // }
        } else {
            //no stagger
            gsap.to(target, duration, {
                autoAlpha: 1,
                x: 0,
                y: 0,
                overwrite: true,
                ease: easing,
                delay,
                scrollTrigger: {
                    trigger: target,
                    start: animationStart,
                    invalidateOnRefresh: true,
                    onEnter: () => {
                        console.log("ANIMATE IN: TARGET");
                    },
                    //  markers: true
                },
            });
            // return () => {
            //   gsap.killTweensOf(target);
            //   gsap.set(target, {clearProps: true});
            //   console.log("ComponentAnimateInAnimation > useLayoutEffect return > kill target");
            // }
        }
    }, []);
    //compRef, animateDirection, delay, duration, easing, stagger, wrapperElement
    return (
        <Component className={wrapperClass} style={{...display}} ref={compRef} {...props}>
            {children}
        </Component>
    );
};

export default ComponentAnimateInAnimation;
