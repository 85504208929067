import React from "react";
//import { graphql } from "gatsby";
//import { getImage, GatsbyImage } from "gatsby-plugin-image";
import ResponsiveImage from "./responsiveImage";



export default function WorkImages({projects, location}) {
    //const isWorkPage = (location.split('/')[1] === "work"? 1 : 0);
    //console.log(projects);
    // console.log(location.split('/')[2]);


    // let isActive;
    
    // <div className="backgrounds" style={{opacity:isWorkPage}}></div>

    return <>
        {projects.map((( project, index ) => (
                // isActive = (location.split('/')[2] === project.attributes.slug)? "active":"",
                // console.log(isActive,location.split('/')[2],project.attributes.slug),
                <ResponsiveImage
                key={index}
                className={`work-background-image u-full-width ${project.attributes.slug}`} //current
                style={{opacity:0}}
                imageData={project.attributes.featuredImage.data}   
                ></ResponsiveImage>
                )))}
      </>
  }
  
