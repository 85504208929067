import React from "react";
import SocialFacebook from "../../static/social-facebook.svg";
import SocialInstagram from "../../static/social-instagram.svg";
import SocialLinkedin from "../../static/social-linkedin.svg";
//import SocialTwitter from "../../static/social-twitter.svg";
import SocialX from "../../static/social-x.svg";
import ComponentAnimateInAnimation from "./animations/componentAnimateIn";


export default function SocialLinks({ children , delay = 1}) {

  return (
    <ComponentAnimateInAnimation className="social-links flex" animationStart="top 95%" wrapperElement="div" duration={0.2} delay={delay} direction="left" stagger={[true,0.2]}>
      <a
        href="https://www.linkedin.com/company/denman-digital"
        target="_blank"
        rel="external"
      >
        <SocialLinkedin alt="Linkedin" />
      </a>
      <a href="https://www.instagram.com/denmandigital" target="_blank" rel="external">
        <SocialInstagram alt="Instagram" />
      </a>
      <a
        href="https://facebook.com/denmandigital/"
        target="_blank"
        rel="external"
      >
        <SocialFacebook alt="Facebook" />
      </a>
      {/* <a
        href="https://twitter.com/denmandigital/"
        target="_blank"
        rel="external"
      >
        <SocialX alt="Twitter" />
      </a> */}
    </ComponentAnimateInAnimation>
  );
}