/**
 * Image component
 *
 * Responsive Image component using img srcset from strapi graphql
 *
 * *** Parameters ***
 *
 *
 */

import React from "react";

export default function ResponsiveImage({
  wrapperElement = "img",
  imageData,
  alt,
  onLoad,
  hasLazyLoading = "true",
  ...props
}) {
  const Component = wrapperElement;
  const hostURL = process.env.CMS_URL;
  let originalSrc,
    originalWidth,
    xlargeSrc,
    xlargeWidth,
    largeSrc,
    largeWidth,
    mediumSrc,
    mediumWidth,
    smallSrc,
    smallWidth;

  // Get Image srcset
  // console.log(imageData);
  originalSrc = hostURL + imageData.attributes.url;
  originalWidth = imageData.attributes.width;
  if(imageData.attributes.formats){

    Object.entries(imageData.attributes.formats).map(
      ([formatSize, formatData]) => {
        switch (formatSize) {
          case "xlarge":
            xlargeSrc = hostURL + formatData.url;
            xlargeWidth = formatData.width;
            break;
            case "large":
              largeSrc = hostURL + formatData.url;
              largeWidth = formatData.width;
              break;
              case "medium":
                mediumSrc = hostURL + formatData.url;
                mediumWidth = formatData.width;
                break;
                case "small":
                  smallSrc = hostURL + formatData.url;
                  smallWidth = formatData.width;
                  break;
                  default:
                    break;
                  }
                }
                );
              } 
                let srcArray = [ smallSrc, mediumSrc, largeSrc, xlargeSrc, originalSrc ];
                let widthArray = [ smallWidth, mediumWidth, largeWidth, xlargeWidth, originalWidth ];
                
                let srcSet = "";
                srcArray.map((src, index) => {
                  if (index === (srcArray.length - 1)) {
                    if (src) {
                      srcSet += `${src} ${widthArray[index]}w`
                    }
                  } else {
                    if (src) {
                      srcSet += `${src} ${widthArray[index]}w,`
                    }
                  }
                })

  return <Component
    srcSet={srcSet}
    src={originalSrc}
    alt={alt ? alt : imageData.attributes.alternativeText}
    loading={hasLazyLoading.toString()}
    onLoad={onLoad}
    {...props}></Component>;
}
