import * as React from "react"
import { useEffect, useLayoutEffect, useRef, useState, useContext } from "react";
//import { Link } from "gatsby";
//import { graphql } from "gatsby";
import PropTypes from "prop-types"
import { useCursorHandlers } from "../hooks/useCursorHandlers";
import { CursorContext } from "../components/layout";
import { PrimaryColorContext } from "../components/layout";
import SocialLinks from "../components/socialLinks";
import Logo from "../../static/denman-logo.svg";
import "../styles/05-app/header.scss"


//Animation libraries
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger.js";
//import GatsbyLink from "gatsby-link";
//import { NoToneMapping } from "three";
gsap.registerPlugin(ScrollTrigger);

//const TRANSITION_DELAY = 1400;
//const TRANSITION_LEN = 1400;
const MOBILE_MENU_BREAKPOINT = 1200;

export default function Header({ location }) {
  const cursor = useContext(CursorContext);
  const [primaryColor, setPrimaryColor] = useContext(PrimaryColorContext);
  const headerRef = useRef();
  const navbarBgRef = useRef();
  const menuRef = useRef();
  //const { viewWidth, viewHeight } = useViewResize();
  const [navType, setNavType] = useState("");
  const navHeight = 90;

  let currentPageHeight;;
  let pageHeight;

  useEffect(() => {
    //navbarBgRef.current.classList.remove('show');

    //console.log("HEADER: primaryColor: " + primaryColor);

    const sectionArray = cursor.currentPage.split('/');
    const currentSection = sectionArray.length > 1 ? cursor.currentPage.split('/')[1] : 0;
    const currentSubSection = sectionArray.length > 2 ? cursor.currentPage.split('/')[2] : 0;

    function handleResize() {
      if (window.innerWidth <= MOBILE_MENU_BREAKPOINT) {
        setNavType("burger");
      } else {
        setNavType("");
      }
    }

    window.addEventListener('resize', handleResize)
    handleResize();

    // Check if page background is white
    (cursor.whitePage === true) ? headerRef.current.classList.add('white-page') : headerRef.current.classList.remove('white-page');

    if (cursor.currentPage !== location.pathname) {
      cursor.currentPage = location.pathname;
    }


    let lastKnownScrollPosition = 0;
    let ticking = false;
    function checkScroll(scrollPos, scrollH) {
      // Do something with the scroll position
      // console.log(scrollPos + ", scrollH: " + scrollH + ", currentSection: " + currentSection + ", currentSubSection: " + currentSubSection);

      currentPageHeight = scrollPos;
      pageHeight = scrollH;

      
      if (menuRef.current) {
        if (!menuRef.current.classList.contains('active')) {
          // Show header at bottom of Single Work Project
          if (currentSection === "work" & currentSubSection.length > 1 & currentPageHeight >= pageHeight - navHeight) {
            navbarBgRef.current.classList.remove('show');
            console.log("H E R E");
            gsap.to(".work-carousel .work-nav", 0.3, {autoAlpha:1});
            //console.log("remove show");
          } else {
            if(currentSection === "work" & currentSubSection.length > 1){
              gsap.to(".work-carousel .work-nav", 0.3, {autoAlpha:0});
            }
            (currentPageHeight >= navHeight) ? navbarBgRef.current.classList.add('show') : navbarBgRef.current.classList.remove('show');
            (currentPageHeight <= navHeight & cursor.whitePage === true) ? headerRef.current.classList.add('white-page') : headerRef.current.classList.remove('white-page');
          }
        }
      }
    }
    function onScroll(e) {

      lastKnownScrollPosition = window.scrollY;

      if (!ticking) {
        window.requestAnimationFrame(function () {
          var body = document.body,
            html = document.documentElement;

          var height = Math.max(body.scrollHeight, body.offsetHeight,
            html.clientHeight, html.scrollHeight, html.offsetHeight);
          //console.log(height-window.innerHeight);
          //console.log(Math.abs(e.target.scrollHeight - e.target.clientHeight - e.target.scrollTop));
          checkScroll(lastKnownScrollPosition, height - window.innerHeight);
          ticking = false;
        });
        ticking = true;
      }

    }
    document.addEventListener('scroll', onScroll);
    //console.log(cursor);

    return () => {
      gsap.killTweensOf("#blob-wrapper");
      window.removeEventListener('resize', handleResize);
      document.removeEventListener('scroll', onScroll);
    }
  }); //[]


  function handleBurgerClick(e) {
    e.preventDefault();
    
    headerRef.current.classList.toggle('active');
    menuRef.current.classList.toggle('active');
    gsap.killTweensOf('nav li');

    gsap.set('nav li', { opacity: 0 });

    gsap.set('.social-wrapper', { opacity: 0 });
    //animate in nav li
    if (menuRef.current.classList.contains('active')) {
      gsap.to('nav li', 0.3, { opacity: 1, delay: 0.4, stagger: 0.1 });
      gsap.to('.social-wrapper', 0.3, { opacity: 1, delay: 1 });

    }

  }

  function handleMenuClick(e) {
    //e.preventDefault();
    console.log("handleMenuClick");
    //remove active class in header when menu clicked
    menuRef.current.classList.remove('active');
    setTimeout(() => {
      if (window.innerWidth <= 1200) {
        try {
          headerRef.current.classList.remove('active');
        } catch (e) {
          console.log(e);
        }
      }
    }, 1100);
  }



  return (
    <>
      <header id="navbar" ref={headerRef}>

        <a className="logo"
          {...useCursorHandlers({ active: "header", size: 0, tlsize: 3000, link: '/' })}>
          <Logo alt="Denman Logo" />
        </a>
        <div className="menu" ref={menuRef}>

          <button className="burger" onClick={handleBurgerClick}></button>
          <div className="burger-blob"></div>
          <nav>
            <ul>
              <li>
                <a
                  aria-current={`${cursor.currentPage.split('/')[1] === "work" ? (cursor.currentPage.split('/')[2] ? "sub-page" : "page") : "false"}`}
                  {...useCursorHandlers({ callback: { handleMenuClick }, active: "header", size: 50, tlsize: 3000, link: '/work/' })}>Work</a>
              </li>
              <li>
                <a
                  aria-current={`${cursor.currentPage.split('/')[1] === "services" ? (cursor.currentPage.split('/')[2] ? "sub-page" : "page") : "false"}`}
                  {...useCursorHandlers({ callback: { handleMenuClick }, active: "header", size: 50, tlsize: 3000, link: '/services/' })}>Services</a>
              </li>
              <li>
                <a
                  aria-current={`${cursor.currentPage.split('/')[1] === "about" ? "page" : "false"}`}
                  {...useCursorHandlers({ callback: { handleMenuClick }, active: "header", size: 50, tlsize: 3000, link: '/about/' })}>About</a>
              </li>
              <li>
                <a
                  aria-current={`${cursor.currentPage.split('/')[1] === "contact" ? "page" : "false"}`}
                  {...useCursorHandlers({ callback: { handleMenuClick }, active: "header", size: 50, tlsize: 3000, link: '/contact/' })}>Contact</a>
              </li>
            </ul>
            <div className="social-wrapper">
              <SocialLinks />
            </div>
          </nav>

        </div>
      </header>
      <div id="navbar-bg" ref={navbarBgRef} style={{ backgroundColor: primaryColor }}></div>
    </>
  );
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}
