/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";
import { Helmet } from "react-helmet";
import { useQueryParam, StringParam } from "use-query-params";
import checkIsMobile from "../helperFunctions/checkIsMobile";
import Header from "./header";
import "../styles/normalize.css";
import "../styles/skeleton.scss";
import "../styles/global.scss";
import "../styles/styles.scss";
// import Scroll from "./smoothScroll";
import BlobCanvas from "./blobCursorCanvas";
//import SectionLabel from "./sectionLabel";
import { useDidMountEffect } from "../hooks/useDidMountEffect";
import WorkImages from "./workImages";
import SEO from "./seo";

//Animations libraries
import gsap from "gsap";
import { Power1 } from "gsap";
import { DrawSVGPlugin } from "gsap/DrawSVGPlugin";

gsap.registerPlugin(DrawSVGPlugin);

//Contexts
export const CursorContext = React.createContext(null);
export const PrimaryColorContext = React.createContext(null);
export const WorkImagesContext = React.createContext(null);

const DARK_GREY = "#272727";

export default function Layout({ children, location }) {
  var [cursor] = useState({
    active: "",
    size: 5000, //0,
    tlactive: "",
    link: "",
    timeouts: [],
    firstLoad: true,
    transitionTime: 2500, //0,
    transitioning: false,
    hover: false,
    currentPage: children.props.path.split("/")[1],
    whitePage: false,
    isTouch: false,
    isMobile: false,
  });


  //const [view, setView] = useQueryParam();
  const [view, setView] = useQueryParam("view", StringParam);
  console.log("cursor.currentPage:" + cursor.currentPage);
  console.log("view:" + view);
  let bodyClass = ((cursor.currentPage === "/work/" && view !== "list")) ? "full-height" : "normal-height";
  var [primaryColor, setPrimaryColor] = useState(DARK_GREY);
  //let isMobile = false;


  //Is layout loaded??
  const [isLoaded, setIsLoaded] = useState(false);
  const [currentPage, setCurrentPage] = useState("");
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
      strapi {
        projects (pagination: {limit: -1}) {
          data {
            attributes {
              featuredImage {
                data {
                  attributes {
                    url
                    width
                    alternativeText
                    formats
                  }
                }
              }
              slug
            }
          }
        }
      }
    }
  `);


  const toggleCursor = (active, size) => {
    cursor.size = size;
    cursor.active = active;
    // console.log("currentSize: " + size);
  };

  const clearTimeouts = () => {
    for (var i = 0; i < cursor.timeouts.length; i++) {
      clearTimeout(cursor.timeouts[i]);
    }
    cursor.timeouts = [];
  };

  function setVH() {
    //let vhOuter = isMobile ? window.outerHeight * 0.01 : document.documentElement.clientHeight * 0.01;
    // let vh = window.innerHeight * 0.01;
    //let vhOuter = document.documentElement.clientHeight * 0.01;
    let vh = document.documentElement.clientHeight * 0.01;
    //vh = 7.61; //test



    //console.log("document.documentElement.clientHeight: " + document.documentElement.clientHeight)
    //console.log("window.innerHeight: " + window.innerHeight);
    document.documentElement.style.setProperty('--vh', `${vh}px`);
    //document.documentElement.style.setProperty('--vhOuter', `${vhOuter}px`);
    //document.documentElement.style.setProperty('--vh', `${vhInner}px`);

  }

  

  

  useDidMountEffect(() => {
    cursor.isTouch = (('ontouchstart' in window) || (navigator.msMaxTouchPoints > 0));
    //console.log("useDidMountEffect > cursor.isTouch: " + cursor.isTouch);
    //console.log(cursor);
    //console.log("useDidMountEffect > cursor.transitioning: " + cursor.transitioning);
    //console.log("useDidMountEffect > cursor.isLoaded: " + isLoaded)();


    const blobGrowTime = cursor.transitionTime / 5; //Used on Grow and Shrink
    const iconAnimationTime = cursor.transitionTime / 5 * 3;
    const blobStartSize = 3000;

    // device detection
    if (checkIsMobile()) {
      cursor.isMobile = true;
      document.getElementsByTagName("html")[0].classList.add('is-mobile');
    }

    function iOSVersion() {
      var match = (navigator.appVersion).match(/OS (\d+)_(\d+)_?(\d+)?/),
          version;
  
      if (match !== undefined && match !== null) {
          version = [
              parseInt(match[1], 10),
              parseInt(match[2], 10),
              parseInt(match[3] || 0, 10)
          ];
          return parseFloat(version.join('.'));
      }
      return false;
  }

  console.log("ios version decimal: " + iOSVersion());
    
    //need this iPad test
    //since iOS 13 iPad no longer returns the user agent identifier
    function isIpad() {
      /* jshint ignore:start*/
      if ((/\b(iPad)\b/.test(navigator.userAgent)
        && /WebKit/.test(navigator.userAgent)
        && !window.MSStream)
        || (navigator.platform === 'MacIntel'
          && navigator.maxTouchPoints
          && navigator.maxTouchPoints === 5)
      ) {
        return true;
      }
      /* jshint ignore:end */
    }

    function shrinkCursorBlob(blobStartSize) {
      clearTimeouts();
      cursor.timeouts.push(setTimeout(function () {
        for (let i = 1000; i >= 0; i--) {
          //console.log(cursor.size);
          cursor.timeouts.push(setTimeout(function () {
            if (i === 0) {
              cursor.transitioning = false;
              cursor.hover = false;
              cursor.active = "";
              cursor.timeouts.shift(); // clear used timeout
              cursor.size = (blobStartSize / 1000) * i;
              cursor.firstLoad = false;
            } else {
              // cursor.active = "header";
              cursor.size = (blobStartSize / 1000) * i;
            }
          }, (blobGrowTime / 1000) * -(i - 1000))
          );
        }
      }, iconAnimationTime)
      );
    }


    //adjust scroll speed by device
    if (isIpad()) {
      console.log("isIpad");
      cursor.isMobile = false; //force iPad to use desktop version
      document.getElementsByTagName("html")[0].classList.remove('is-mobile');
    }

    //console.log("useDidMountEffect cursor.isTouch: " + cursor.isTouch + ", isMobile: " + isMobile + ", isIpad: " + isIpad());

    console.log("view: "+ view);

    

    // BlobTransition First Load
    if (cursor.firstLoad && !cursor.transitioning && cursor.currentPage !== "/thank-you/") {
      //console.log("First Load BlobTransition");
      //cursor.transitionTime = 2500;
      cursor.active = "header";
      cursor.transitioning = true;
      cursor.size = blobStartSize;

      const blobGrowTime = cursor.transitionTime / 5;
      const iconAnimationTime = (cursor.transitionTime / 5) * 3;

      //console.log("blobStartSize: " + blobStartSize + ", blobGrowTime: " + blobGrowTime)

      // Icon animation
      const iconAnimationTimeSec = iconAnimationTime / 1000;
      gsap.set(`#headerDenmanIcon .st2`, { drawSVG: 0 });
      gsap.set(`#headerDenmanIcon`, { y: -100, opacity: 0, display: "block" });
      gsap.to(`#headerDenmanIcon`, iconAnimationTimeSec / 6, {
        y: 0,
        opacity: 1,
        ease: Power1.easeOut,
      });
      gsap.to(`#headerDenmanIcon .st2`, {
        drawSVG: true,
        duration: iconAnimationTimeSec / 3,
        ease: Power1.easeOut,
        delay: iconAnimationTimeSec / 6 + 0.1,
      });
      gsap.to(`#headerDenmanIcon .st2`, {
        fill: "white",
        duration: iconAnimationTimeSec / 6,
        ease: Power1.easeOut,
        delay: iconAnimationTimeSec / 2,
      });
      gsap.to(`#headerDenmanIcon`, iconAnimationTimeSec / 6, {
        y: 0,
        opacity: 0,
        ease: Power1.easeOut,
        delay: (iconAnimationTimeSec / 6) * 5,
      });
      gsap.set(`#headerDenmanIcon .st2`, {
        drawSVG: 0,
        fill: "none",
        delay: 3,
      });

      //shrink blob to cursor
      shrinkCursorBlob(blobStartSize);

    } else if (cursor.transitioning, isLoaded) {
      
      //shrink blob to cursor
      shrinkCursorBlob(blobStartSize);
    }
  });

  useEffect(() => {
    //console.log("Layout > useEffect : bodyClass: " + bodyClass + ", cursor.currentPage: " + cursor.currentPage);
    //console.log(location.pathname);
    window.addEventListener('resize', setVH, false);
    setVH();

    if (currentPage !== location.pathname) {
      //scrollbar exists and we're on a new page
      //set position to top left
      // window.bodyScrollBar.setPosition(0, 0); // disable SmoothScroll
      setCurrentPage(location.pathname);
      // console.log("setcurrent");
    }

    let currentSection = currentPage.split('/')[1];
    //console.log("currentSection: " + currentSection);
    if (currentSection !== "work") {
      setPrimaryColor(DARK_GREY);
    }

    // }
    setIsLoaded(true);

  }, [bodyClass, cursor.currentPage, setVH, currentPage, location.pathname]); //, [currentPage, location.pathname, setVH]

  // useLayoutEffect(() => {
  //   console.log("Layout > useLayoutEffect");
  // });

  return (
    
    <CursorContext.Provider value={cursor}>
      <PrimaryColorContext.Provider value={[primaryColor, setPrimaryColor]}>
        <SEO></SEO>
        <Helmet bodyAttributes={{ class: bodyClass }}></Helmet>
        <Header
          siteTitle={data.site.siteMetadata?.title || `Denman Digital`}
          location={location}
        />
        <BlobCanvas cursor={cursor} loadedIn="header" />
        <WorkImages
          location={children.props.path}
          projects={data.strapi.projects.data}
        />
        <BlobCanvas cursor={cursor} loadedIn="home" />
        <div id="container">
          <main>{isLoaded ? children : <></>}</main>
        </div>
      </PrimaryColorContext.Provider>
    </CursorContext.Provider>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};
